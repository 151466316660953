<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="my-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="border-bottom mb-3">
                                    <data-table id="example-data-table" 
                                        :options="options" 
                                        :data-set="data.dataList"
                                        :page-size="data.pageSize" 
                                        @change-action="changeAction"
                                        @change-status="changeStatus"
                                        @pagination="pagination" @sorting="sorting"
                                        @limit-change="limitChange
                                    "/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="addMilestoneModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Role Manager</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <Form ref="resetForm" @submit="formAction(data.setURL, data.inputFormData)"
                      v-slot="{ errors }" :validation-schema="schema">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Role</label>
                                <Field type="text" class="form-control" v-model="data.inputFormData.role_name"
                                       :class="{'border-danger': errors.role}" name="role" readonly="readonly"/>
                                <span class="text-danger" v-if="errors.role">{{ errors.role }}</span>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Name</label>
                                <Field type="text" class="form-control" v-model="data.inputFormData.user_name"
                                       :class="{'border-danger': errors.user}" name="user" readonly="readonly"/>
                                <span class="text-danger" v-if="errors.user">{{ errors.user }}</span>
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-control-label">Proxy</label>
                                <Field as="select" class="form-control" name="proxy"
                                       v-model="data.inputFormData.proxy_user_id"
                                       :class="{'border-danger': errors.proxy}">
                                    <option :value="''">Select Proxy User</option>
                                    <option v-for="(member, index) in data.inputFormData.proxy" :key="index"
                                            :value="member.id">{{ member.name }}
                                    </option>
                                </Field>
                                <span class="text-danger" v-if="errors.proxy">{{ errors.proxy }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary"
                            :disabled="invitationData.invitation_status === 'Invited' ? true : false"
                        >Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <!-- /Modal -->

</template>

<script>
    import HelperFunction from '@/common/helpers';
    import DataTable from "@/common/components/datatable";
    import {useStore} from 'vuex';
    import {useRoute} from 'vue-router';
    import {computed, onMounted, reactive, watch} from 'vue';
    import {Field, Form} from "vee-validate";

    export default {
        name: "RoleManager",
        components: {
            DataTable, Form, Field
        },
        setup() {
            const _this = this
            const {data, getAllData, editFormData, deleteListData, storeFormData, resetForm, permissionResolver} = HelperFunction();
            const store = useStore();
            const route = useRoute();
            const company = store.getters.getSelectedBoard;
            const user = store.getters.getProfile;
            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const projectStatus = computed(() => store.getters.getProjectStatus);

            let cKey = reactive({
                dataCkey: data
            });
            let setParams = {
                company_id: company.id,
                project_id: route.params.id,
                sort: 'desc',
                column: 'id'
            };

            data.setURL = vueConfig.Project.RoleManagerEndPoint;

            const translations = computed(() => {
                return store.state.translations;  
            });

            // SET DATATABLE PROPS
            let options = {
                name: translations.value?.project_leadership || 'Project Leadership',
                columns: [{
                    title: translations.value?.role || 'Role',
                    type: 'text',
                    key: 'role',
                    sort: false,
                },
                    {
                        title: translations.value?.name || 'Name',
                        type: 'text',
                        key: 'name',
                        sort: false,
                    },
                    {
                        title: translations.value?.email || 'Email',
                        type: 'text',
                        key: 'proxy_email',
                        sort: false,
                    },
                    {
                        title: translations.value?.proxy || 'Proxy',
                        type: 'text',
                        key: 'proxy_name',
                        sort: false,
                    }
                ],
                showAction: true,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: data.permission.canEdit
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: data.permission.canDelete
                    }
                ],
            }

            onMounted(async () => {
                if(projectStatus == 'Completed') {
                    options.showAction = false
                }

                await getAllData(data.setURL, setParams)
                    .then(() => {
                        data.dataList = data.dataList.approvers;
                    });
            });

            

            /** watch **/
            permissionResolver('role-manager', true);

            watch(() => projectStatus.value, (value) => {
                if(value && value == 'Completed')
                {
                    options.showAction = false
                }
            }, {immediate: true});
            /** watch end **/

            async function changeAction(action, row) {
                if (action.type === 'edit') {
                    await editFormData({
                        url: `${data.setURL}/${row.id}`,
                        modalId: 'showModal'
                    }, false, setFormData)
                }
                if (action.type === 'delete') {
                    deleteListData(`${data.setURL}/${row.id}`, setParams)
                }
            }
            const pagination = (page) => {
                setParams.page = page;
                getAllData(data.setURL, setParams);
            }
            const limitChange = (limit) => {
                setParams.limit = limit;
                delete setParams.page
                getAllData(data.setURL, setParams);
            }
            const sorting = (column) => {
                setParams.column = column.key;
                setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc';
                getAllData(data.setURL, setParams);
            }
            function setFormData(formData) {
                data.inputFormData.role_name = formData.role.name ? formData.role.name : '';
                data.inputFormData.user_name = formData.user.name ? formData.user.name : '';
                data.inputFormData.proxy = formData.members ? formData.members : [];
            }
            async function formAction(getUrl, inputData) {
                let data = {
                    id: inputData.id,
                    user_id: user.id,
                    proxy_user_id: inputData.proxy_user_id,
                    role_name: inputData.role_name,
                    user_name: inputData.user_name
                };
                if (inputData.id) {
                    data._method = 'PUT';
                    getUrl = `${getUrl}/${inputData.id}`;
                }
                await storeFormData({url: getUrl, modalId: 'showModal'}, data)
                    .then(() => {
                        getAllData(vueConfig.Project.RoleManagerEndPoint, setParams)
                            .then(() => {
                                cKey.dataCkey.dataList = cKey.dataCkey.dataList.approvers;
                            });
                    });
            }

            return {
                data, getAllData, editFormData, deleteListData, storeFormData, options,
                changeAction, pagination, limitChange, sorting, formAction, cKey, resetForm,
                invitationData, projectStatus, translations
            }
        }
    }
</script>

<style scoped>

</style>